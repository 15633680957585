import React from 'react';
import T from 'prop-types';

import Icon from '../Icon';
import routes from '../../routes';
import s from './TasksPagination.module.css';

class TasksPagination extends React.Component {
  state = {
    location: null,
  };

  componentDidMount() {
    const { location } = window;
    this.setState({
      location,
    });
  }

  componentDidUpdate(prevProp, prevState) {
    const { location } = window;
    if (prevState.location) {
      if (prevState.location.href === location.href) return;
    }
    this.onUpdate(location);
  }

  onUpdate = obj => {
    this.setState({ location: obj });
  };

  setPath = i => {
    const index = String(i);
    const path = `${routes.TASK}?theme=js&id=js-`;
    if (index.length === 1) return `${path}00${index}`;
    if (index.length === 2) return `${path}0${index}`;
    return `${path}${index}`;
  };

  render() {
    const { taskListLength, list } = this.props;
    const { location } = this.state;
    // console.log('taskListLength', taskListLength);
    // console.log('list.length', list.length);
    const LAST =
      list.length < taskListLength - 1 ? list.length + 1 : list.length;

    // console.log('LAST', LAST);

    // console.log('location', location);
    // console.log('typeof location', typeof location);

    // if (location.pathname === routes.TASK) console.log('task ');
    let id = 0;
    let isRender;
    if (location) {
      isRender = list && location.pathname === routes.TASK;
      id = +location.search.slice(-3);
      // console.log('list', list);
      // console.log(
      //   'location.pathname === routes.TASK',
      //   location.pathname === routes.TASK,
      // );
    }
    const current = +id % 1000;
    // console.log('current', current);
    // const prev = current > 0;
    // const next = current < LAST;

    return (
      <>
        {isRender && (
          <nav className={s.dataPagination}>
            {current === 0 ? (
              <span className={s.disabled}>
                <Icon className={s.iconDisabled} name="back" />
              </span>
            ) : (
              <a className={s.back} href={this.setPath(current - 1)}>
                <Icon className={s.icon} name="back" />
              </a>
            )}
            {/* {prev && (
              <a className={s.prev} href={this.setPath(current - 1)}>
                <span className={s.item}>{current - 1}</span>
              </a>
            )} */}
            <span className={s.current} href={this.setPath(current)}>
              <b className={s.activeItem}>{current + 1}</b>
              <b className={s.item}>/{taskListLength}</b>
            </span>
            {/* {next && (
              <a className={s.next} href={this.setPath(current + 1)}>
                <span className={s.item}>{current + 1}</span>
              </a>
            )} */}
            {current === LAST + 1 ||
            (current === 0 && list.length === 0) ||
            current > list.length - 1 ? (
              <span className={s.disabled}>
                <Icon className={s.iconDisabled} name="forward" />
              </span>
            ) : (
              <a className={s.forward} href={this.setPath(current + 1)}>
                <Icon className={s.icon} name="forward" />
              </a>
            )}
          </nav>
        )}
      </>
    );
  }
}

TasksPagination.defaultProps = {
  list: null,
  taskListLength: 10,
};

TasksPagination.propTypes = {
  list: T.arrayOf(T.string),
  taskListLength: T.number,
};

export default TasksPagination;
