/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import T from 'prop-types';

import shortid from 'shortid';
import Input from '../../components/Input';
import s from './CertificatePage.module.css';
import ErrorMessage from '../../components/ErrorMessage';
import { loadSession } from '../../services/api/webstorage/session';
import { updateUser } from '../../services/api/db-user/api-db-user';
import routes from '../../routes';

const INIT_USER = {
  name: '',
  lastName: '',
  isError: false,
  errorMessage: '',
  token: '',
  tel: '',
  congratulation: false,
};

class CertificatePage extends Component {
  state = { ...INIT_USER, isReturn: false };

  componentDidMount() {
    try {
      const { token } = loadSession();
      this.setState({
        token,
      });
    } catch (err) {
      this.setState({ isReturn: true });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { token, name, lastName, tel } = this.state;
    updateUser({
      token,
      name,
      lastName,
      tel,
      isReceivedCertificate: true,
      isComplited: { js: true },
    }).then(data => {
      if (data.success) {
        this.setState({ congratulation: true });
      }
    });
  };

  hundleKeyPressedSubmit = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const nameId = shortid.generate();
    const lastNameId = shortid.generate();
    const telId = shortid.generate();
    const {
      name,
      lastName,
      tel,
      errorMessage,
      isError,
      congratulation,
      isReturn,
    } = this.state;
    // {isReturn ? (<Redirect to={routes.HOME} />) :
    return isReturn ? (
      <Redirect to={routes.HOME} />
    ) : (
      <div className={s.container}>
        <div className={s.formWrapper}>
          <h1 className={s.title}>Для получения сертификата заполните форму</h1>
          {congratulation ? (
            <h2>Поздравляем с успешным прохождением этого курса!</h2>
          ) : (
            <form className={s.form} onSubmit={this.handleSubmit}>
              <Input
                id={nameId}
                isAsterisk={false}
                type="text"
                name="name"
                initValue={name}
                placeholder="Имя *"
                change={this.handleChange}
                keyAction={this.hundleKeyPressedSubmit}
              />
              <Input
                id={lastNameId}
                isAsterisk={false}
                type="text"
                name="lastName"
                placeholder="Фамилия *"
                initValue={lastName}
                change={this.handleChange}
                keyAction={this.hundleKeyPressedSubmit}
              />
              <Input
                id={telId}
                isAsterisk={false}
                type="tel"
                name="tel"
                placeholder="Телефон *"
                initValue={tel}
                change={this.handleChange}
                keyAction={this.hundleKeyPressedSubmit}
              />
              <div className={s.buttonWrapper}>
                <button type="submit" className={s.btn}>
                  Получить сертификат
                </button>
              </div>
              {isError && <ErrorMessage msg={errorMessage} />}
            </form>
          )}
        </div>
      </div>
    );
  }
}
export default CertificatePage;
