import React from 'react';
import T from 'prop-types';
import parse from 'html-react-parser';

import replacer from '../../helpers/replacer';
import s from './TaskPanel.module.css';

function TaskPanel(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const { task } = props;
  const { textOfTask } = task;

  return (
    <div className={s.container}>
      <h4 className={s.titleAccent}>[Задача_]</h4>
      <ol className={s.orderedList}>
        {textOfTask.description.map((elm, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={idx} value="0" className={s.listStyleNone}>
            <p className={s.taskTopic}>{parse(replacer(elm))}</p>
          </li>
        ))}
        {textOfTask.conditions.map((elm, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={idx} className={s.testItem}>
            <span className={s.testItemText}>{parse(replacer(elm))}</span>
          </li>
        ))}
      </ol>
    </div>
  );
}

TaskPanel.propTypes = {
  task: T.shape({
    textOfTask: T.shape({
      description: T.arrayOf(T.string),
      conditions: T.arrayOf(T.string),
    }).isRequired,
  }).isRequired,
};

export default TaskPanel;
