import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import T from 'prop-types';

import routes from '../../routes';
import s from './Navigation.module.css';
import logo from '../../logo.png';
import BurgerButton from '../BurgerButton';
import Icon from '../Icon';
import { loadSession } from '../../services/api/webstorage/session';
import WithUser from '../../services/context/user/WithUser';
import TasksPagination from '../TasksPagination';
import data from '../../data';

// TODO total: 14 - брать из taskList
class Navigation extends Component {
  state = {
    isClicked: false,
    // homePage: false,
    isRegistration: false,
    total: data.length,
    idInt: null,
    pathname: '/',
    search: '',
    back: '/',
    next: '/',
  };

  static propTypes = {
    user: T.shape({
      email: T.string,
      token: T.string,
      resolvedTasksId: T.shape({
        js: T.array,
      }),
    }).isRequired,
  };

  componentDidMount() {
    // const homePage = window.location.pathname !== '/';
    // this.setState({ homePage });
    this.handleUserMenuUpdate();
    this.handleUpdate();
  }

  componentWillUnmount() {
    const { idInt } = this.state;
    if (idInt !== null) {
      clearInterval(idInt);
    }
  }

  handleUpdate = () => {
    // console.log('pathname', pathname);
    // console.log('search', search);
    // console.log('location', window.location);
    let delay = 250;
    const id = setInterval(() => {
      const { pathname } = window.location;
      const { search } = window.location;
      if (pathname !== this.state.pathname || search !== this.state.search) {
        this.setState({
          pathname,
          search,
          back: pathname + search,
          next: pathname + search,
        });
      }
      if (loadSession('ident')) {
        this.handleUserMenuUpdate();
        // clearInterval(id);
        delay = 500;
        this.setState({
          idInt: null,
        });
      }
    }, delay);
    this.setState({
      idInt: id,
    });
  };

  handleUserMenuUpdate = () => {
    try {
      const session = loadSession('ident');
      if (session) this.setState({ isRegistration: true });
    } catch (err) {
      throw new Error('Read error of the sessionStore');
    }
  };

  handleToggle = () => {
    this.setState(prevState => ({ isClicked: !prevState.isClicked }));
  };

  handleKeyPressToggle = evt => {
    if (evt.keyCode === 13) {
      this.setState(prevState => ({ isClicked: !prevState.isClicked }));
    }
  };

  render() {
    // const { isClicked, homePage, isRegistration, total } = this.state;
    const { isClicked, isRegistration, total, back, next, search } = this.state;
    const { user } = this.props;
    // const resolved = user.resolvedTasksId.js.length || 0;
    return (
      <>
        <ul className={s.menu}>
          <li className={s.logoWrapper}>
            <NavLink
              className={s.logoLink}
              exact
              to={routes.HOME}
              activeStyle={{ color: 'white' }}
            >
              <img className={s.logo} src={logo} alt="Logo GoIT" />
            </NavLink>
          </li>
          {/* <li
            className={s.menuItemProgress}
          >{`Прогресс: ${resolved}/${total}`}</li> */}
          <li className={s.paginationWrapper}>
            {search ? (
              <TasksPagination
                list={user.resolvedTasksId.js}
                taskListLength={total}
              />
            ) : (
              ''
            )}
          </li>
          {!isRegistration ? (
            <>
              <li className={s.menuItem}>
                <div className={s.menuItemIconWrapper}>
                  <Icon
                    className={s.menuItemIcon}
                    name="user-light"
                    width={28}
                    height={28}
                  />
                </div>
              </li>
              <li className={s.menuItem}>
                <NavLink
                  className={s.menuLink}
                  to={{
                    pathname: routes.LOGIN,
                    state: { back, next },
                  }}
                  activeStyle={{ color: 'white' }}
                >
                  <span className={s.linkText}>Войти</span>
                </NavLink>
              </li>
              <li className={s.menuItem}>
                <span className={s.menuItemDecor}>|</span>
              </li>
              <li className={s.menuItem}>
                <NavLink
                  className={s.menuLink}
                  to={{
                    pathname: routes.REGISTRATION,
                    state: { back, next },
                  }}
                  activeStyle={{ color: 'white' }}
                >
                  <span className={s.linkText}>Регистрация</span>
                </NavLink>
              </li>
            </>
          ) : (
            <li className={s.menuItem} data-name="user.mail">
              {user.email}
            </li>
          )}
          {!isRegistration && (
            <li
              className={s.burgerButtonWrapper}
              onClick={this.handleToggle}
              onKeyPress={this.handleKeyPressToggle}
              title="Нажми клавишу 'Enter'"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
            >
              <BurgerButton isClick={isClicked} />
            </li>
          )}
        </ul>
        {/* vertical menu */}
        {/* <li className={s.menuItem}>
            <NavLink
              className={s.menuLink}
              exact
              to={{
                pathname: routes.TASK,
                search: `theme=js&id=js-000&topic=first-task`,
              }}
              activeStyle={{ color: 'white' }}
              onClick={this.handleToggle}
              >
              <span className={s.linkAnimate}>Тесты</span>
              </NavLink>
            </li> */}
        {!isRegistration ? (
          <>
            <ul className={!isClicked ? s.menuVerticalClose : s.menuVertical}>
              <li className={s.menuItem}>
                <NavLink
                  className={s.menuLink}
                  to={{
                    pathname: routes.LOGIN,
                    state: { back, next },
                  }}
                  activeStyle={{ color: 'white' }}
                  onClick={this.handleToggle}
                >
                  <span className={s.linkAnimate}>Войти</span>
                </NavLink>
              </li>
              <li className={s.menuItem}>
                <NavLink
                  className={s.menuLink}
                  to={{
                    pathname: routes.REGISTRATION,
                    state: { back, next },
                  }}
                  activeStyle={{ color: 'white' }}
                  onClick={this.handleToggle}
                >
                  <span className={s.linkAnimate}>Регистрация</span>
                </NavLink>
              </li>
            </ul>
          </>
        ) : null}
      </>
    );
  }
}

export default WithUser(Navigation);
