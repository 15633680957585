/* eslint-disable camelcase */
import React, { Component } from 'react';
import queryString from 'query-string';
import T from 'prop-types';
import Loader from 'react-loader-spinner';
import { Redirect } from 'react-router-dom';

import WithUser from '../../services/context/user/WithUser';
import ErrorMessage from '../../components/ErrorMessage';
import TaskPage from './TaskPage';
import s from './TaskPage.module.css';

// import {
//   fetchGetTasksTopics,
//   fetchGetTaskById,
// } from '../../services/api/db-task/api-db-task';

import {
  getTasksTopics,
  getTaskById,
} from '../../services/api/db-task/api-data-task';

import { saveLocal, loadLocal } from '../../services/api/webstorage/local';
import defaultTaskList from '../../config/defaultTaskList';
import initTask from '../../config/initTask';
import routes from '../../routes';

const loader = (
  <Loader
    className={s.loader}
    type="Circles"
    color="#e96105"
    height={80}
    width={80}
  />
);

class TaskPageContainer extends Component {
  state = {
    taskList: defaultTaskList,
    task: initTask,
    isError: false,
  };

  static defaultProps = {};

  static propTypes = {
    history: T.shape({
      location: T.shape({
        search: T.string,
        state: T.shape({
          task: T.shape({ id: T.string }),
          taskList: T.arrayOf(T.shape({})),
        }),
      }),
    }).isRequired,
    user: T.shape({
      resolvedTasksId: T.shape({}).isRequired,
      currentTaskId: T.string,
    }).isRequired,
  };

  componentDidMount() {
    const taskList = loadLocal('taskList');
    if (taskList) {
      this.setState({ taskList });
    }
    const id = this.getId();
    // const { taskList } = this.state;
    if (taskList.find(e => e.id === id)) {
      this.saveUserCurrentId(id);
      if (taskList.length === 1) {
        // fetchGetTasksTopics()
        getTasksTopics()
          .then(data => {
            this.setState({ taskList: data });
            saveLocal(data, 'taskList');
          })
          .then(() => {
            // fetchGetTaskById(id)
            getTaskById(id)
              .then(data => this.setState({ task: data }))
              .catch(err => <ErrorMessage message={err} />);
          });
      } else {
        // fetchGetTaskById(id)
        getTaskById(id)
          .then(data => this.setState({ task: data }))
          .catch(err => <ErrorMessage message={err} />);
      }
    } else {
      this.setState({ isError: true });
    }
  }

  getId = () => {
    const { history } = this.props;
    const { location } = history;
    const { id } = queryString.parse(location.search);
    return id;
  };

  saveUserCurrentId = id => {
    const { user: currentUser } = this.props;
    currentUser.currentTaskId = id;
    saveLocal(currentUser, 'user');
  };

  render() {
    const { taskList, task, isError } = this.state;
    const isErrorPath = task === undefined || taskList === undefined;
    return (
      <>
        {isErrorPath || isError ? (
          <Redirect
            to={{
              pathname: routes.HOME,
            }}
          />
        ) : null}
        {taskList.length > 1 && task.id.length > 0 ? (
          <TaskPage task={task} taskList={taskList} />
        ) : (
          loader
        )}
      </>
    );
  }
}

export default WithUser(TaskPageContainer);
