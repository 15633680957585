import React from 'react';
// import T from 'prop-types';
// import Loader from 'react-loader-spinner';

import s from './HomePage.module.css';
// import LinkToTask from '../../components/LinkToTask';

// const loader = (
//   <Loader
//     className="loader"
//     type="Circles"
//     color="#e96105"
//     height={80}
//     width={80}
//   />
// );

// function HomePage({ taskList, isLoading }) {
function HomePage() {
  return (
    <div className={s.containerFluid}>
      <div className={s.container}>
        <h1 className={s.title}>
          <span className={s.SupTitle}>
            [ Попробуй себя <br />в программировании ] <br />
          </span>
          и реши задачки на самом популярном <br /> языке
          <wbr />
          <span className={s.accentTitle}> JavaScript</span>&nbsp;_
        </h1>
        <div className={s.linkWrapper}>
          {/* {isLoading ? loader : <LinkToTask task={taskList[0]} />} */}
          <a className={s.link} href="/task/?theme=js&id=js-000">
            начать
          </a>
        </div>
      </div>
    </div>
  );
}

HomePage.propTypes = {
  // taskList: T.arrayOf(T.shape({})).isRequired,
  // isLoading: T.bool.isRequired,
};

export default HomePage;
