/* eslint-disable react/destructuring-assignment */
import React from 'react';
import T from 'prop-types';

import s from './ErrorMessage.module.css';

function ErrorMessage({ message, changeMessage }) {
  return (
    <div className={s.container}>
      <h3 className={s.info}>Что-то пошло не так :-(</h3>
      <h4 className={s.title}>Сообщение об ошибке:</h4>
      <p className={s.message}>{message}</p>
      <div className={s.btnWrapper}>
        <button className={s.btn} onClick={changeMessage} type="button">
          Попробуй еще
        </button>
      </div>
    </div>
  );
}

ErrorMessage.defaultProps = {
  message: '',
};

ErrorMessage.propTypes = {
  message: T.string,
  changeMessage: T.func.isRequired,
};

export default ErrorMessage;
