/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import T from 'prop-types';
// import queryString from 'query-string';
// import { Redirect } from 'react-router-dom';

// import FrameComponent from '../../components/FrameComponent';
import Theory from '../../components/Theory';
import TaskPanel from '../../components/TaskPanel';
import Button from '../../components/Button';
import s from './TaskPage.module.css';
import Tests from '../../components/Tests';
import TestsString from '../../components/TestsString';
import ErrorBoundary from '../../components/ErrorBoundary';
// import { getTaskById } from '../../services/api/db-task/api-data-task';
// import ErrorMessage from '../../components/ErrorMessage';

import 'codemirror/lib/codemirror.css';
import '../../styles/material.css';
import 'codemirror/theme/neat.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import initTask from '../../config/initTask';
import options from '../../helpers/codeMirrorOptions';

class TaskPage extends Component {
  state = {
    value: null,
    task: initTask,
    isActiveLeftTop: true,
    isActiveLeftBottom: true,
    isActiveRightTop: true,
    isActiveRightBottom: true,
    isStartTesting: false,
    isHint: false,
  };

  instance = null;

  static defaultProps = {
    history: {
      location: {
        search: '',
        state: {
          task: initTask,
          taskList: [],
        },
      },
    },
  };

  static propTypes = {
    task: T.shape({
      id: T.string.isRequired,
      hint: T.string,
      video: T.string,
      code: T.string,
    }).isRequired,
    history: T.shape({
      location: T.shape({
        search: T.string,
        state: T.shape({
          task: T.shape({ id: T.string }),
          taskList: T.arrayOf(T.string),
        }),
      }),
    }),
    taskList: T.arrayOf(
      T.shape({
        id: T.string,
      }),
    ).isRequired,
  };

  componentDidMount() {
    const { task } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { code } = task;
    this.setState({
      task,
      value: code,
    });
  }

  // componentDidUpdate(prevProps) {
  //   const id = this.getId();
  //   if (prevProps.task.id !== id) {
  //     // this.setTask(id);
  //     console.log('id', id);
  //     console.log('prevProps.task.id', prevProps.task.id);
  //   }
  // }

  componentWillUnmount() {
    this.handleReload();
  }

  // setTask = id => {
  //   getTaskById(id)
  //     .then(data => this.setState({ task: data }))
  //     .catch(err => <ErrorMessage message={err} />);
  // };

  // getId = () => {
  //   const { history } = this.props;
  //   const { location } = history;
  //   const { id } = queryString.parse(location.search);
  //   return id;
  // };

  // getRedirectState = () => {
  //   try {
  //     const { history } = this.props;
  //     const { location } = history;
  //     const { state } = location;
  //     const { task } = state;
  //     return task;
  //   } catch (err) {
  //     return undefined;
  //   }
  // };

  handleRun = () => {
    this.setState({ isStartTesting: true, isActiveRightBottom: true });
  };

  handleReload = () => {
    window.location.reload();
  };

  handleReset = () => {
    this.handleReload();
  };

  handleOpenHint = () => {
    this.setState({ isHint: true });
  };

  handleCloseHint = () => {
    this.setState({ isHint: false });
  };

  hideOtherPanels = ({ target }) => {
    const result = {
      ...{
        isActiveLeftTop: false,
        isActiveLeftBottom: false,
        isActiveRightTop: false,
        isActiveRightBottom: false,
      },
      ...{ [target.dataset.action]: true },
    };

    this.setState({ ...result });
  };

  setInitCodeEditor = (editor, data, value) => {
    this.setState({ value });
  };

  render() {
    const { taskList } = this.props;
    const {
      task,
      value,
      isActiveLeftTop,
      isActiveLeftBottom,
      isActiveRightTop,
      isActiveRightBottom,
      isStartTesting,
      isHint,
      // restart,
    } = this.state;
    const {
      usereResultValue,
      describeTest,
      source,
      tests,
      rightAnswer,
    } = task.testOfCodeText;
    const isLeftPanel = isActiveLeftTop || isActiveLeftBottom;
    const isRightPanel = isActiveRightTop || isActiveRightBottom;
    // const { hint, video, id } = task;
    const { id } = task;
    return (
      <>
        {isHint && (
          <div className={s.helpWrapper}>
            <div className={s.helpControls}>
              <Button
                klass="controlsBtnActive"
                text="Закрыть"
                icon="close"
                setAction={this.handleCloseHint}
              />
            </div>
            <video controls className={s.video}>
              <source src={task.video} type="video/mp4" />
              Ваш браузер не поддерживает элемент <code>video</code>.
            </video>
          </div>
        )}
        <div className={s.containerFluid}>
          <button
            className={isActiveLeftTop ? s.btnCollapsActive : s.btnCollaps}
            data-action="isActiveLeftTop"
            onClick={this.hideOtherPanels}
            type="button"
          >
            теория
          </button>
          <button
            className={isActiveLeftBottom ? s.btnCollapsActive : s.btnCollaps}
            data-action="isActiveLeftBottom"
            onClick={this.hideOtherPanels}
            type="button"
          >
            условия
          </button>
          <button
            className={isActiveRightTop ? s.btnCollapsActive : s.btnCollaps}
            data-action="isActiveRightTop"
            onClick={this.hideOtherPanels}
            type="button"
          >
            код
          </button>
          <button
            className={isActiveRightBottom ? s.btnCollapsActive : s.btnCollaps}
            data-action="isActiveRightBottom"
            onClick={this.hideOtherPanels}
            type="button"
          >
            результат
          </button>
        </div>
        <div className={s.container}>
          <section className={isLeftPanel ? s.LeftPanel : s.LeftPanelHidden}>
            <div
              className={
                isActiveLeftTop ? s.PanelContainer : s.LeftTopPanelHidden
              }
            >
              <Theory task={task} />
            </div>
            <div
              className={
                isActiveLeftBottom ? s.PanelContainer : s.LeftBottomPanelHidden
              }
            >
              <TaskPanel task={task} />
            </div>
          </section>
          <section className={isRightPanel ? s.RightPanel : s.RightPanelHidden}>
            <div>
              <div
                className={
                  isActiveRightTop ? s.RightTopPanel : s.RightTopPanelHidden
                }
              >
                <h4 className={s.editor}>[Редактор_]</h4>
                <ErrorBoundary>
                  <CodeMirror
                    className={s.CodeMirror}
                    editorDidMount={editor => {
                      this.instance = editor;
                    }}
                    value={value}
                    options={options}
                    onBeforeChange={this.setInitCodeEditor}
                    style={{ height: 'auto' }}
                  />
                </ErrorBoundary>
                <div className={s.controls}>
                  {!isStartTesting ? (
                    <Button
                      klass="controlsBtnActive"
                      text="ОК"
                      icon="run"
                      setAction={this.handleRun}
                    />
                  ) : null}
                  <Button
                    klass="controlsBtn"
                    text="Обновить"
                    icon="reset"
                    setAction={this.handleReset}
                  />
                  <Button
                    klass="controlsBtn"
                    text="Помощь"
                    icon="hint"
                    setAction={this.handleOpenHint}
                  />
                  {/* <a
                  href={hint}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <Button klass="controlsBtn" text="Hint" icon="hint" />
                </a>
                <a
                  href={video}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <Button klass="controlsBtn" text="Video" icon="video" />
                </a> */}
                </div>
              </div>
            </div>
            <div>
              <div
                className={
                  isActiveRightBottom
                    ? s.PanelContainer
                    : s.RightBottomPanelHidden
                }
              >
                <h4 className={s.editor}>[Результат_]</h4>
                {isStartTesting ? (
                  <ErrorBoundary>
                    {/* <FrameComponent> */}
                    {id === 'js-000' ? (
                      <TestsString
                        className="FrameContainer"
                        userCode={value}
                        rightAnswer={rightAnswer}
                        usereResultValue={usereResultValue}
                        id={id}
                        describeTest={describeTest}
                        tests={tests}
                        source={source}
                        taskList={taskList}
                      />
                    ) : (
                      <Tests
                        className="FrameContainer"
                        userCode={value}
                        rightAnswer={rightAnswer}
                        usereResultValue={usereResultValue}
                        id={id}
                        describeTest={describeTest}
                        tests={tests}
                        source={source}
                        taskList={taskList}
                      />
                    )}
                    {/* </FrameComponent> */}
                  </ErrorBoundary>
                ) : (
                  <div className={s.TestRules} />
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default TaskPage;
