const defaulTaskList = [
  { id: 'js-000', topic: 'Комментарии' },
  { id: 'js-001', topic: 'Строка. Синтаксис.' },
  { id: 'js-002', topic: 'Переменные: const' },
  { id: 'js-003', topic: 'Переменные: let. Тип данных undefined.' },
  { id: 'js-004', topic: 'Тип данных числа. Оператор + для чисел и строк' },
  { id: 'js-005', topic: 'Приведение типов. Число из строки.' },
  { id: 'js-006', topic: 'Строка: символы и индексы.' },
  { id: 'js-007', topic: 'Строка. Использование методов строки.' },
  { id: 'js-008', topic: 'Строка и подстрока. Метод slice.' },
  { id: 'js-009', topic: 'Найди промокод с помощью JS' },
];
export default defaulTaskList;
