/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-eval */
/* eslint-disable func-names */
import React, { useState, useEffect } from 'react';
import { mocha } from 'mocha';
import chai from 'chai';
import T from 'prop-types';

import TestsResult from '../TestsResult';
import WithUser from '../../services/context/user/WithUser';

const runTest = (
  cb,
  tests,
  rightAnswerArg,
  sourceArg,
  userCode,
  describeTest,
  usereResultValue,
) => {
  mocha.setup('bdd');
  const { assert } = chai;

  const failures = [];
  const successes = [];
  let testsQuantity = 0;

  const getUserCode = function getUserCode() {
    let result;
    let source;
    if (usereResultValue && sourceArg) {
      try {
        eval(
          `${userCode};
          result = ${usereResultValue};
          source = ${sourceArg};
          `,
        );
      } catch (err) {
        console.error('usereResultValue && sourceArg error:', err);
      }
    } else {
      try {
        eval(
          `${userCode};
        `,
        );
      } catch (err) {
        console.error('error:', err);
      }
    }
    // изолируем result от оставшегося кода пользователя
    return { result, source, rightAnswerArg, userCode };
  };

  const {
    result,
    source,
    rightAnswerArg: rightAnswer,
    userCode: userCodeArg,
  } = getUserCode();
  describe(describeTest, function() {
    eval(tests);
    // console.log('getUserCode result', result);
    // console.log('getUserCode source', source);
    // console.log('getUserCoderightAnswerArg', rightAnswerArg);
    const { length } = this.tests;
    testsQuantity = length;
  });

  afterEach(function() {
    const { title } = this.currentTest;
    const { state } = this.currentTest;
    if (state === 'passed') {
      successes.push(title);
    } else if (state === 'failed') {
      failures.push({ title });
    }
  });

  /*
  // вывод тестов в консоль
  // https://mochajs.org/#min
  // mocha.reporter('min');
  */
  mocha.reporter('JSON');
  // console.log('mocha.reporter', mocha.reporter);

  mocha.run(() => {
    cb({
      failures,
      successes,
      testsQuantity,
    });
  });
};

// css
const testTitle = {
  fontFamily: '"Montserrat", sans-serif',
  textAlign: 'center',
};
// css
const testWrapper = {
  margin: '1rem 0',
  padding: '0 .5rem 0 1rem',
};

function Tests({
  userCode,
  rightAnswer,
  usereResultValue,
  id,
  describeTest,
  tests,
  source,
  user,
  taskList,
}) {
  const [result, setRes] = useState(false);
  useEffect(
    () =>
      runTest(
        setRes,
        tests,
        rightAnswer,
        source,
        userCode,
        describeTest,
        usereResultValue,
      ),
    [describeTest, rightAnswer, source, tests, userCode, usereResultValue],
  );

  return (
    <div style={{ ...testWrapper }}>
      <h3 style={{ ...testTitle }}>Результаты тестов:</h3>
      {true && result && (
        <>
          <TestsResult result={result} taskId={id} taskList={taskList} />
        </>
      )}
    </div>
  );
}

Tests.defaultProps = {
  rightAnswer: null,
  usereResultValue: null,
};

Tests.propTypes = {
  userCode: T.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rightAnswer: T.any,
  usereResultValue: T.string,
  describeTest: T.string.isRequired,
  tests: T.string.isRequired,
  id: T.string.isRequired,
  source: T.string.isRequired,
  user: T.shape({}).isRequired,
  taskList: T.arrayOf(
    T.shape({
      id: T.string,
    }),
  ).isRequired,
};

export default WithUser(Tests);
