import React, { Component } from 'react';
import T from 'prop-types';
import { Redirect } from 'react-router-dom';

import shortid from 'shortid';
import Input from '../../components/Input';
import s from './LoginPage.module.css';
import WithUser from '../../services/context/user/WithUser';
import ErrorMessage from '../../components/ErrorMessage';
import {
  loginUser,
  getUser,
  updateUser,
} from '../../services/api/db-user/api-db-user';
import { saveSession } from '../../services/api/webstorage/session';
import addUniqObjResolvedTasksId from '../../helpers/addUniqObjResolvedTasksId';
import { saveLocal } from '../../services/api/webstorage/local';
import routes from '../../routes';

const INIT_USER = {
  email: '',
  password: '',
};
const INIT = {
  isError: false,
  errorMessage: '',
  isToHome: false,
  ...INIT_USER,
};

class LoginPage extends Component {
  state = { ...INIT };

  static defaultProps = {
    user: {
      ...INIT_USER,
    },
  };

  static defaultProps = {
    location: T.shape({
      state: T.shape({
        message: '',
        back: '/',
        next: '/task/?theme=js&id=js-001',
      }),
    }),
  };

  static propTypes = {
    location: T.shape({
      state: T.shape({ message: T.string, back: T.string, next: T.string }),
    }),
    history: T.shape({
      goBack: T.func,
      push: T.func,
      length: T.number,
      entries: T.array,
      index: T.number,
    }).isRequired,
    user: T.shape({
      email: T.string,
      password: T.string,
      name: T.string,
      currentTaskId: T.string,
      lastname: T.string,
      resolvedTasksId: T.shape({}),
      isComplited: T.shape({}),
      isReceivedCertificate: T.bool,
    }),
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  hundleGoBack = () => {
    const { back } = this.props.location.state;
    if (this.props.history) {
      const { history } = this.props;
      history.push(back);
    }
    // const { history } = this.props;
    // if (history.length > 0) {
    //   history.goBack();
    // } else {
    //   this.setState({ isToHome: true });
    // }
  };

  hundleGoNext = () => {
    const { next } = this.props.location.state;
    if (this.props.history) {
      const { history } = this.props;
      history.push(next);
    }
  };

  hundleKeyPressedGoBack = e => {
    e.preventDefault();
    if (e.key === 'Enter') {
      this.hundleGoBack();
    }
  };

  hundleClear = () => {
    this.setState({ ...INIT });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    const { user } = this.props;
    try {
      const data = await loginUser({ email, password });
      if (data.success) {
        const { token } = data;
        saveSession({ token }, 'ident');
        const remoteUser = await getUser({ token });
        const updatedUser = addUniqObjResolvedTasksId(remoteUser, user);
        user.resolvedTasksId = updatedUser.resolvedTasksId;
        remoteUser.resolvedTasksId = updatedUser.resolvedTasksId;
        remoteUser.email = email;
        user.email = email;
        saveLocal(remoteUser);
        updateUser({ ...remoteUser, token }).then(() => {
          if (user.currentTaskId === '') {
            this.setState({ isToHome: true });
            return;
          }
          this.hundleGoNext();
        });
      } else {
        this.setState({ isError: true, errorMessage: data.message });
      }
    } catch (err) {
      this.setState({ isError: true, errorMessage: 'err' });
    }
  };

  hundleKeyPressedSubmit = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const { location } = this.props;
    const { state } = location;
    const { message } = state || '';

    const { email, password, isError, errorMessage, isToHome } = this.state;
    const emailId = shortid.generate();
    const passwordId = shortid.generate();
    return (
      <div className={s.container}>
        <div className={s.formWrapper}>
          <h1 className={s.title}>Войти</h1>
          {message && <h4>{message}</h4>}
          <form className={s.form} onSubmit={this.handleSubmit}>
            <Input
              id={emailId}
              labelText=" E-mail"
              type="email"
              name="email"
              initValue={email}
              change={this.handleChange}
              keyAction={this.hundleKeyPressedSubmit}
            />
            <Input
              id={passwordId}
              labelText=" Пароль"
              type="password"
              name="password"
              initValue={password}
              change={this.handleChange}
              keyAction={this.hundleKeyPressedSubmit}
            />
            <div className={s.buttonWrapper}>
              <button
                className={s.btn}
                type="button"
                onClick={this.hundleGoBack}
                onKeyPress={this.hundleKeyPressedGoBack}
              >
                назад
              </button>
              <button
                className={s.btnAcent}
                type="submit"
                onKeyPress={this.hundleKeyPressedSubmit}
              >
                вход
              </button>
            </div>
          </form>
          {isError && (
            <ErrorMessage
              message={errorMessage}
              changeMessage={this.hundleClear}
            />
          )}
        </div>
        {isToHome ? <Redirect to={{ pathname: routes.HOME }} /> : null}
      </div>
    );
  }
}

export default WithUser(LoginPage);
