/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import T from 'prop-types';
import parse from 'html-react-parser';

import Icon from '../Icon';
import CodeViewer from '../CodeViewer';
import replacer from '../../helpers/replacer';
import s from './Theory.module.css';

function Theory(props) {
  const [isHide, setHide] = useState(false);
  // eslint-disable-next-line react/destructuring-assignment
  const { task } = props;
  const { id, main, topic, descriptions } = task;
  // console.log('task', task);
  return (
    <div className={s.container} id={id}>
      <h2 className={isHide ? s.hide : s.theme}>
        <span className={s.themeInline}>[ {main}_ ]</span>
      </h2>
      <h1 className={isHide ? s.hide : s.topic}>{topic}</h1>
      {descriptions.map(e => (
        <div key={e.title}>
          <details
            className={s.details}
            open
            onClick={() => (isHide ? setHide(false) : setHide(true))}
          >
            <summary className={s.summary}>
              {/* {isHide ? 'Показать' : 'Скрыть'} */}
              {isHide ? (
                <Icon name="visability-off" />
              ) : (
                <Icon name="visability" />
              )}
            </summary>
            <h4 className={s.title}>{e.title}</h4>
            {e.images.src ? (
              <figure>
                <img src={e.images.src} alt={e.images.alt} />
                <figcaption>
                  <p>{e.images.description}</p>
                </figcaption>
              </figure>
            ) : null}
            <div className={s.description}>
              {parse(replacer(e.description))}
            </div>
          </details>
          <h4 className={s.titleExample}>Пример:</h4>
          <CodeViewer value={e.example} />
        </div>
      ))}
    </div>
  );
}

// Theory.defaultProps = {

// }

Theory.propTypes = {
  task: T.shape({
    id: T.string.isRequired,
    main: T.string.isRequired,
    topic: T.string.isRequired,
    descriptions: T.arrayOf(
      T.shape({
        title: T.string,
        src: T.string,
        alt: T.string,
        description: T.string,
        example: T.string,
      }),
    ),
  }).isRequired,
};

export default Theory;
