const addUniqObjResolvedTasksId = (rm, loc) => {
  const local = { ...loc };
  const remote = { ...rm };
  if (remote.resolvedTasksId === null) {
    remote.resolvedTasksId = [];
  }
  Object.keys(remote.resolvedTasksId).forEach(e => {
    if (local.resolvedTasksId[e]) {
      remote.resolvedTasksId[e].forEach(el => {
        if (!local.resolvedTasksId[e].includes(el)) {
          local.resolvedTasksId[e].push(el);
        }
      });
    } else {
      local.resolvedTasksId[e] = remote.resolvedTasksId[e];
    }
  });
  return local;
};

export default addUniqObjResolvedTasksId;
