import React from 'react';
import T from 'prop-types';

import s from './Header.module.css';

const Header = ({ children }) => {
  return (
    <div className={s.containerFluid}>
      <header className={s.container}>{children}</header>
    </div>
  );
};

Header.defaultProps = {
  children: <h2>Header</h2>,
};

Header.propTypes = {
  children: T.element,
};

export default Header;
