import { Component } from 'react';
import T from 'prop-types';

import ErrorMessage from '../ErrorMessage';
import { saveLocal } from '../../services/api/webstorage/local';

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    ErrorMessage(error);
    return { hasError: true };
  }

  static defaultProps = {
    children: '',
  };

  static propTypes = {
    children: T.element,
  };

  state = { hasError: false };

  componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    saveLocal({ error, errorInfo }, 'error');
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return ErrorMessage('');
    }

    return children;
  }
}
export default ErrorBoundary;
