import data from '../../../data/index';

const getAll = () =>
  Promise.resolve(
    data.map(e => ({
      id: e.id,
      type: e.type,
      ...e.jsonText,
    })),
  );

const getTasksTopics = () =>
  Promise.resolve(
    data.map(e => ({
      id: e.id,
      type: e.jsonText.descriptions[0].title,
    })),
  );

const getTaskById = idt => {
  const { id, type, jsonText } = data.find(e => e.id === idt);
  return Promise.resolve({ id, type, ...jsonText });
};

export { getAll, getTasksTopics, getTaskById };
