/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-eval */
/* eslint-disable func-names */
import React, { useState, useEffect } from 'react';
import { mocha } from 'mocha';
import chai from 'chai';
import T from 'prop-types';

import TestsResult from '../TestsResult';
import WithUser from '../../services/context/user/WithUser';

const runTest = (
  cb,
  tests,
  rightAnswerArg,
  sourceArg,
  userCode,
  describeTest,
  usereResultValue,
) => {
  mocha.setup('bdd');
  const { assert } = chai;

  const failures = [];
  const successes = [];
  let testsQuantity = 0;

  describe(describeTest, function() {
    const code = userCode;
    const lines = code.split('\n');
    const oneLines = lines.filter(e => e.includes('//'));
    const oneLinesFree = lines.filter(e => !e.includes('//'));
    const isPresent = (arr, word) =>
      arr.findIndex(e => e.includes(word)).length !== -1;

    function isRightOrdered(arr, template, word) {
      const str = arr.find(e => e.includes(template) && e.includes(word));
      if (str === -1) return false;
      const tIndex = str.indexOf(template);
      const wIndex = str.indexOf(word);
      if (tIndex === -1 || wIndex === -1) return false;
      return tIndex < wIndex;
    }

    function isOneComments(arr) {
      if (arr.length === 0) return false;
      const open = arr.filter(e => e.includes('/*'));
      const close = arr.filter(e => e.includes('*/'));
      return open.length + close.length === 2;
    }

    function isMultyOrder(arr) {
      const open = '/*';
      const word = 'multy';
      const close = '*/';
      const openStr = arr.find(e => e.includes(open));
      const openIndex = arr.findIndex(e => e.includes(open));
      const wordStr = arr.find(e => e.includes(word));
      const wordIndex = arr.findIndex(e => e.includes(word));
      const closeStr = arr.find(e => e.includes(close));
      const closeIndex = arr.findIndex(e => e.includes(close));
      if (openIndex === -1 || wordIndex === -1 || closeIndex === -1)
        return false;
      if (openIndex < wordIndex && wordIndex < closeIndex) return true;
      if (openIndex === wordIndex && wordIndex < closeIndex) {
        return openStr.indexOf(open) < openStr.indexOf(word);
      }
      if (openIndex < wordIndex && wordIndex === closeIndex) {
        return wordStr.indexOf(word) < wordStr.indexOf(close);
      }
      return false;
    }

    function isNotTextOutComments(data) {
      const oneLine = '//';
      const onelines = data
        .filter(e => e.includes(oneLine))
        .some(e => {
          const from = e.indexOf(oneLine);
          const s = e.slice(0, from);
          return /\S/.test(s);
        });
      if (onelines) {
        console.log('комметнарий вне однострочного комментария', onelines);
        return false;
      }

      const arr = data.filter(e => !e.includes('//'));
      const open = '/*';
      const word = 'multy';
      const close = '*/';
      const openStr = arr.find(e => e.includes(open));
      const openIndex = arr.findIndex(e => e.includes(open));
      const wordStr = arr.find(e => e.includes(word));
      const wordIndex = arr.findIndex(e => e.includes(word));
      const closeStr = arr.find(e => e.includes(close));
      const closeIndex = arr.findIndex(e => e.includes(close));

      // комментрия нет, текст есть
      if (openIndex === -1 || wordIndex === -1 || closeIndex === -1) {
        console.log('комментрия нет, текст есть');
        return !/\S/.test(arr.join(''));
      }
      // комментарии есть, но текст вне комментария также есть
      const str = arr
        .filter((e, i) => i < openIndex || i > closeIndex)
        .join('');
      if (/\S/.test(str)) {
        console.log('комментарии есть, но текст вне комментария также есть');
        return false;
      }

      // текст вне комментария на одной строке с началом комментария
      if (openStr) {
        const s = openStr.slice(0, openStr.indexOf(open));
        if (/\S/.test(s)) {
          console.log(
            'текст вне комментария на одной строке с началом комментария',
          );
          return false;
        }
      }
      // текст вне комментария на одной строке с началом комментария, словом и завершением комментария
      if (openIndex === wordIndex && wordIndex === closeIndex) {
        const from = openStr.indexOf(open);
        const to = openStr.indexOf(close) + 1;
        const s = openStr.slice(0, from) + openStr.slice(to + 1);
        if (/\S/.test(s)) {
          console.log(
            'текст вне комментария на одной строке с началом комментария, словом и завершением комментария',
          );
          return false;
        }
      }
      // текст вне комментария на одной строке с началом комментария и словом
      if (openIndex === wordIndex && wordIndex < closeIndex) {
        const from = openStr.indexOf(open);
        const s = openStr.slice(0, from);
        if (/\S/.test(s)) {
          console.log(
            'текст вне комментария на одной строке с началом комментария и словом',
          );
          return false;
        }
      }
      // текст вне комментария на одной строке со словом и завершением комментария
      if (openIndex < wordIndex && wordIndex === closeIndex) {
        const from = wordStr.indexOf(close);
        const s = openStr.slice(from + 1);
        if (/\S/.test(s)) {
          console.log(
            'текст вне комментария на одной строке со словом и завершением комментария',
          );
          return false;
        }
      }
      // текст вне комментария на одной строке с началом комментария
      if (openStr) {
        const s = openStr.slice(0, openStr.indexOf(open));
        if (/\S/.test(s)) {
          console.log(
            'текст вне комментария на одной строке с началом комментария',
          );
          return false;
        }
      }
      // текст вне комментария на одной строке с завершением комментария
      if (closeStr) {
        const s = closeStr.slice(openStr.indexOf(open) + 2);
        if (/\S/.test(s)) {
          console.log(
            'текст вне комментария на одной строке с завершением комментария',
          );
          return false;
        }
      }
      return true;
    }

    // eval(tests);
    it('В строке с однострочным комментарием должно быть слово one', () => {
      assert.equal(isPresent(oneLines, 'one'), true);
    });
    it('В строке с однострочным комментарием // и слово one записаны в правильном порядке', () => {
      assert.equal(isRightOrdered(oneLines, '//', 'one'), true);
    });
    it('Текста вне комментариев быть не должно', () => {
      assert.equal(isNotTextOutComments(lines), true);
    });
    it('Многострочный комментарий должен быть один', () => {
      assert.equal(isOneComments(oneLinesFree), true);
    });
    it('В многострочным комментарии /*, слово multy и */ должны быть записаны в правильном порядке', () => {
      assert.equal(isMultyOrder(oneLinesFree), true);
    });
    const { length } = this.tests;
    testsQuantity = length;
  });

  afterEach(function() {
    const { title } = this.currentTest;
    const { state } = this.currentTest;
    if (state === 'passed') {
      successes.push(title);
    } else if (state === 'failed') {
      failures.push({ title });
    }
  });

  /*
  // вывод тестов в консоль
  // https://mochajs.org/#min
  // mocha.reporter('min');
  */
  // mocha.reporter('landing');
  mocha.reporter('json');
  // console.log('mocha.reporter', mocha.reporter);

  mocha.run(() => {
    cb({
      failures,
      successes,
      testsQuantity,
    });
  });
};

// css
const testTitle = {
  fontFamily: '"Montserrat", sans-serif',
  textAlign: 'center',
};
// css
const testWrapper = {
  margin: '1rem 0',
  padding: '0 .5rem 0 1rem',
};

function TestsString({
  userCode,
  rightAnswer,
  usereResultValue,
  id,
  describeTest,
  tests,
  source,
  user,
  taskList,
}) {
  const [result, setRes] = useState(false);
  useEffect(
    () =>
      runTest(
        setRes,
        tests,
        rightAnswer,
        source,
        userCode,
        describeTest,
        usereResultValue,
      ),
    [describeTest, rightAnswer, source, tests, userCode, usereResultValue],
  );

  return (
    <div style={{ ...testWrapper }}>
      <h3 style={{ ...testTitle }}>Результаты тестов:</h3>
      {true && result && (
        <>
          <TestsResult result={result} taskId={id} taskList={taskList} />
        </>
      )}
    </div>
  );
}

TestsString.defaultProps = {
  rightAnswer: null,
  usereResultValue: null,
};

TestsString.propTypes = {
  userCode: T.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rightAnswer: T.any,
  usereResultValue: T.string,
  describeTest: T.string.isRequired,
  tests: T.string.isRequired,
  id: T.string.isRequired,
  source: T.string.isRequired,
  user: T.shape({}).isRequired,
  taskList: T.arrayOf(
    T.shape({
      id: T.string,
    }),
  ).isRequired,
};

export default WithUser(TestsString);
