import React from 'react';
import T from 'prop-types';

import s from './Input.module.css';

const PATTERNS = {
  email: '[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}',
  //   password: '/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{6,}/',
  password: '[a-zA-Z0-9-._]{2,16}',
  tel: '[+([0-9]{3})-[0-9]{2}[0-9]{3}-[0-9]{3}-[0-9]{4}',
  // eslint-disable-next-line no-useless-escape
  text: '^[a-zA-ZА-Яа-яЁёs]+$',
};

function Input({
  id,
  labelClassName,
  textClassName,
  inputClassName,
  labelText,
  isAsterisk,
  type,
  name,
  initValue,
  change,
  keyAction,
  isRequired,
  placeholder,
}) {
  return (
    <label className={s[labelClassName]} htmlFor={id}>
      {labelText && (
        <span className={s[textClassName]}>
          {labelText}
          {isAsterisk && <em className={s.asterisk}>*</em>}
          &nbsp;&nbsp;
        </span>
      )}
      <input
        className={s[inputClassName]}
        type={type}
        id={id}
        name={name}
        pattern={PATTERNS[type]}
        value={initValue}
        onChange={change}
        onKeyPress={keyAction}
        required={isRequired}
        placeholder={placeholder}
      />
    </label>
  );
}

Input.defaultProps = {
  labelClassName: 'label',
  textClassName: 'span',
  inputClassName: 'input',
  isAsterisk: true,
  isRequired: true,
  placeholder: '',
};

Input.propTypes = {
  labelClassName: T.string,
  id: T.string.isRequired,
  textClassName: T.string,
  inputClassName: T.string,
  labelText: T.string.isRequired,
  type: T.string.isRequired,
  name: T.string.isRequired,
  initValue: T.string.isRequired,
  isAsterisk: T.bool,
  change: T.func.isRequired,
  keyAction: T.func.isRequired,
  isRequired: T.bool,
  placeholder: T.string,
};

export default Input;
