import React, { useState, useEffect } from 'react';

import s from './Footer.module.css';
import logoGGM from '../../images/icons/logoGGM.svg';

const Footer = () => {
  const [homePage, sethomePage] = useState(true);
  useEffect(() => {
    sethomePage(window.location.pathname === '/');
  }, [homePage]);

  return (
    <div className={homePage ? s.darkTheme : s.lightTheme}>
      <footer className={s.container}>
        <p className={s.copyright}>
          <span className={s.signOfCopyright}>&copy;</span> 2020{' '}
          <span className={s.signOfDivider}>|</span> Политика конфиденциальности
        </p>
        <p className={s.author}>
          <a className={s.link} href="https://github.com/gennadiy1970">
            Разработка: Горбулин Геннадий
            <img
              className={s.image}
              src={logoGGM}
              width={24}
              height={24}
              alt="Gorbulin Gennadiy"
            />
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
