import React from 'react';
import T from 'prop-types';

import Icon from '../Icon';
import s from './Button.module.css';

const Button = props => {
  const { klass, icon, text, setAction } = props;
  return (
    <button
      className={s[klass]}
      type="button"
      onClick={setAction}
      data-text={text}
    >
      <span className={s.controlsText}>{text}</span>
      <Icon name={icon} />
    </button>
  );
};

Button.defaultProps = {
  klass: 'controlsBtn',
  setAction: () => undefined,
};

Button.propTypes = {
  klass: T.string,
  icon: T.string.isRequired,
  text: T.string.isRequired,
  setAction: T.func,
};

export default Button;
