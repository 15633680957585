import React from 'react';
import T from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import {
  titleTestsResult,
  testWrapper,
  commonStyle,
  successStyle,
  errorsStyle,
  controlsStyle,
  controlsTextStyle,
  controlsBtnActiveStyle,
  linkStyle,
  svgActiveStyle,
} from './TestResultsStyle';
import routes from '../../routes';
import WithUser from '../../services/context/user/WithUser';
import icons from '../../images/icons/ico-sprite.svg';
import { saveLocal } from '../../services/api/webstorage/local';
import { BEFORE_REGISTEATION_WITH_CANCEL } from '../../config/registrationConfig';
import { loadSession } from '../../services/api/webstorage/session';
// import getUnresolvedTaskIdArray from '../../helpers/getUnresolvedTaskIdArray';
import { updateUser } from '../../services/api/db-user/api-db-user';

function reload() {
  setTimeout(() => window.location.reload(), 100);
}

function TestsResult({ result, taskId, user, taskList }) {
  const { successes, failures, testsQuantity } = result;
  const currentUser = user;
  const passes = successes.length;
  const errors = failures.length;
  const allTests = passes + errors;
  const isReady = errors === 0 && testsQuantity > 0 && testsQuantity === passes;
  let isRegistered = false;
  let session;
  try {
    session = loadSession('ident');
    if (session) {
      isRegistered = true;
    }
  } catch (err) {
    throw new Error('Read error of the sessionStore');
  }

  const lastIndex = taskList.length - 1;
  const id = taskId;
  const resolvedByUser = currentUser.resolvedTasksId.js;
  if (isReady && !resolvedByUser.includes(id)) {
    resolvedByUser.push(id);
    saveLocal(currentUser, 'user');
    if (isRegistered) {
      updateUser({ ...session, ...currentUser });
    }
  }
  resolvedByUser.sort();

  // const unresolvedTasks = getUnresolvedTaskIdArray(taskList, resolvedByUser);
  // с чем дальше работать - со списком нерешенных или общим ?

  const index = taskList.findIndex(e => e.id === id);
  let nextTask = taskList[index];
  if (lastIndex > index) {
    nextTask = taskList[index + 1];
  }

  if (lastIndex === index && isReady && isRegistered) {
    return (
      <Redirect
        to={{
          pathname: routes.CERTIFICATE,
        }}
      />
    );
  }

  if (lastIndex - 1 === index && !isRegistered && isReady) {
    return (
      <Redirect
        to={{
          pathname: routes.REGISTRATION,
          state: {
            isForward: false,
            next: `/task/?theme=js&id=${nextTask.id}`,
            // next: {
            //   pathname: routes.TASK,
            //   search: `/task/theme=js&id=${nextTask.id}`,
            // },
            back: `/task/?theme=js&id=${taskList[index].id}`,
            // back: {
            //   pathname: routes.TASK,
            //   search: `theme=js&id=${taskList[index].id}`,
            // },
          },
        }}
      />
    );
  }

  if (BEFORE_REGISTEATION_WITH_CANCEL === index && !isRegistered && isReady) {
    return (
      <Redirect
        to={{
          pathname: routes.REGISTRATION,
          state: {
            isForward: true,
            next: `/task/?theme=js&id=${nextTask.id}`,
            back: `/task/?theme=js&id=${taskList[index].id}`,
            // next: {
            //   pathname: routes.TASK,
            //   search: `theme=js&id=${nextTask.id}`,
            // },
            // back: {
            //   pathname: routes.TASK,
            //   search: `theme=js&id=${taskList[index].id}`,
            // },
          },
        }}
      />
    );
  }

  return (
    <div style={{ ...testWrapper }}>
      {isReady && (
        <>
          <h4 style={{ ...titleTestsResult, ...commonStyle }}>
            Задание выполнено верно
          </h4>
          <div style={{ ...controlsStyle }}>
            <Link
              onClick={() => reload()}
              to={{
                pathname: routes.TASK,
                search: `theme=js&id=${nextTask.id}`,
                state: {
                  taskList,
                  task: nextTask,
                },
              }}
              style={{ ...linkStyle, ...controlsBtnActiveStyle }}
            >
              <span style={{ ...controlsTextStyle }}>
                Перейти к следующей задаче
              </span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                className="icon"
                style={{ ...svgActiveStyle }}
              >
                <use xlinkHref={`${icons}#next`} />
              </svg>
            </Link>
          </div>
        </>
      )}
      {errors !== 0 && (
        <>
          <h4 style={{ ...titleTestsResult, ...commonStyle }}>
            Задание не выполнено из-за ошибок в:
          </h4>
          <ol>
            {failures.map(e => (
              <li key={e.title}>{e.title}</li>
            ))}
          </ol>
          <h3 style={{ ...titleTestsResult }}>
            Всего тестов: <span style={{ ...commonStyle }}>{allTests}</span>.
          </h3>
          <h4 style={{ ...titleTestsResult }}>
            Успешно пройдено: <span style={{ ...successStyle }}>{passes}</span>.
          </h4>
          <h4 style={{ ...titleTestsResult }}>
            Ошибок в тестах: <span style={{ ...errorsStyle }}>{errors}</span>.
          </h4>
        </>
      )}
    </div>
  );
}

TestsResult.defaultProps = {
  result: { failures: [], successes: [] },
};

TestsResult.propTypes = {
  result: T.shape({
    failures: T.array,
    successes: T.array,
    testsQuantity: T.number,
  }),
  taskId: T.string.isRequired,
  user: T.shape({
    resolvedTasksId: T.shape().isRequired,
    currentTheme: T.string,
    currentTaskId: T.string,
  }).isRequired,
  taskList: T.arrayOf(
    T.shape({
      id: T.string,
    }),
  ).isRequired,
};

export default WithUser(TestsResult);
