/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import UserContext from './UserContext';

const WithUser = WrappedComponent => {
  return function WithContext(props) {
    return (
      <UserContext.Consumer>
        {user => <WrappedComponent {...props} user={user} />}
      </UserContext.Consumer>
    );
  };
};

export default WithUser;
