const titleTestsResult = {
  fontFamily: '"Acrom", sans-serif',
  textAlign: 'left',
};

const testWrapper = {
  margin: '1rem 0',
};

const commonStyle = {
  color: '#28384a',
};
const successStyle = {
  color: 'green',
};
const errorsStyle = {
  color: 'red',
};

const controlsStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '20px',
};

const controlsTextStyle = {
  marginRight: '10px',
  fontSize: '12px',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

const svgStyle = {
  fill: '#6b6f7a',
};
const svgActiveStyle = {
  fill: '#fff',
};

const controlsBtnStyle = {
  padding: '10px 24px',
  border: 'none',
  outline: 'none',
  backgroundColor: '#28384a',
  color: '#6b6f7a',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  margin: '1rem auto',
};

const controlsBtnActiveStyle = {
  ...controlsBtnStyle,
  backgroundColor: '#ff6b08',
  color: '#fff',
};

const linkStyle = {
  textDecoration: 'none',
  color: 'inherit',
};

export {
  titleTestsResult,
  testWrapper,
  commonStyle,
  successStyle,
  errorsStyle,
  controlsStyle,
  controlsTextStyle,
  svgStyle,
  controlsBtnStyle,
  controlsBtnActiveStyle,
  linkStyle,
  svgActiveStyle,
};
