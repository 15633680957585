import origin from '../../../config/origin';

const baseUrl = `${origin}/api/v1/user/`;

/* eslint-disable consistent-return */

const registerUser = async ({ email, password }) => {
  try {
    const url = `${baseUrl}register`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('password', password);
    urlencoded.append('email', email);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Could not fetch register user, received ${error}`);
    // console.log('err:', error);
  }
};

const loginUser = async ({ email, password }) => {
  // console.log('email', email);
  // console.log('password', password);
  // console.log('typeof email', typeof email);
  // console.log('typeof password', typeof password);
  try {
    const url = `${baseUrl}login`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('password', password);
    urlencoded.append('email', email);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };
    const response = await fetch(url, requestOptions);
    // console.log('response', response);
    const data = await response.json();
    // console.log('data', data);
    return data;
  } catch (error) {
    console.log('err:', error);
  }
};

const getUser = async ({ token }) => {
  try {
    const url = `${baseUrl}get`;
    const urlencoded = new URLSearchParams();
    urlencoded.append('token', token);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: urlencoded,
      redirect: 'follow',
    });
    if (!response.ok) {
      throw new Error(`Could not fetch ${url}, received ${response.status}`);
    }
    const data = await response.json();
    data.resolvedTasksId = JSON.parse(data.resolvedTasksId);
    if (typeof data.resolvedTasksId === 'string') {
      data.resolvedTasksId = JSON.parse(data.resolvedTasksId);
    }
    data.isCompleted = JSON.parse(data.isCompleted);
    if (typeof data.isCompleted === 'string') {
      data.isCompleted = JSON.parse(data.isCompleted);
    }
    return data;
  } catch (err) {
    console.log('err:', err);
  }
};

const updateUser = async user => {
  try {
    const url = `${baseUrl}update`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();

    Object.entries(user).forEach(([prop, value]) => {
      let str = value;
      if (typeof value === 'object') {
        str = JSON.stringify(value);
      }
      urlencoded.append(prop, str);
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log('err:', error);
  }
};

const deleteUser = async ({ token }) => {
  try {
    const url = `${baseUrl}delete`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('token', token);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    console.log('data.success:', data.success);
    return data;
  } catch (error) {
    console.log('err:', error);
  }
};

export { registerUser, loginUser, getUser, updateUser, deleteUser };
