import React from 'react';
import T from 'prop-types';

import Header from '../Header';
import Footer from '../Footer';
import Navigation from '../Navigation';

function Layout({ children }) {
  return (
    <>
      <Header>
        <Navigation />
      </Header>
      <main>{children}</main>
      <Footer />
    </>
  );
}

Layout.defaultProps = {
  children: <h1>Main Page</h1>,
};

Layout.propTypes = {
  children: T.element,
};

export default Layout;
