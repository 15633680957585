const initTask = {
  id: '',
  main: '',
  topic: '',
  descriptions: [
    {
      title: '',
      description: '',
      example: '',
      images: {
        src: '',
        alt: '',
        description: '',
      },
    },
  ],
  keywords: [''],
  textOfTask: {
    description: [''],
    conditions: [''],
  },
  code: '',
  testOfCodeText: '',
  testOfCodeResult: '',
  hint: '',
  video: '',
};

export default initTask;
