/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import T from 'prop-types';
import { Redirect } from 'react-router-dom';

import shortid from 'shortid';
import Input from '../../components/Input';
import s from './RegistrationPage.module.css';
import WithUser from '../../services/context/user/WithUser';
import { registerUser } from '../../services/api/db-user/api-db-user';
import { saveSession } from '../../services/api/webstorage/session';
import ErrorMessage from '../../components/ErrorMessage';
import routes from '../../routes';
import { saveLocal } from '../../services/api/webstorage/local';

const INIT_USER = {
  email: '',
  password: '',
};

const INIT = {
  ...INIT_USER,
  isError: false,
  errorMessage: '',
  isReturn: true,
  isToLogin: false,
  isToNext: false,
};

const INIT_STATE = {
  isForward: false,
  back: '/',
  next: `theme=js&id=js-000`,
  // back: {
  //   pathname: '/',
  //   search: '',
  // },
  // next: {
  //   pathname: routes.TASK,
  //   search: `theme=js&id=js-000&topic=first-task`,
  // },
};

class RegistrationPage extends Component {
  state = { ...INIT };

  static defaultProps = {
    location: {
      state: { ...INIT_STATE },
    },
  };

  static propTypes = {
    history: T.shape({
      go: T.func,
      push: T.func,
      goBack: T.func,
      length: T.number,
    }).isRequired,
    location: T.shape({
      state: T.shape({
        isForward: T.bool,
        back: T.string,
        next: T.string,
        // back: T.shape({ pathname: T.string, search: T.string }),
        // next: T.shape({ pathname: T.string, search: T.string }),
      }),
    }),
    user: T.shape({ currentTaskId: T.string, email: T.string }).isRequired,
  };

  componentWillUnmount() {}

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    const { user } = this.props;
    const sessionUser = { email, password };
    registerUser({ ...sessionUser })
      .then(data => {
        const { success, message } = data;
        if (success) {
          const { token } = data;
          saveSession({ token }, 'ident');
          user.email = email;
          // saveLocal(user, 'user');
          this.setState({ isToNext: true });
          this.hundleGoBack();
        }
        if (
          success === false &&
          message === 'User with this email already registered'
        ) {
          this.setState({
            isError: true,
            errorMessage: 'Пользователь с таким email уже зарегистрирован',
            isToLogin: true,
          });
        }
        return true;
      })
      .catch(err => {
        this.setState({ isError: true, errorMessage: err });
      });
  };

  hundleKeyPressedGoBack = e => {
    e.preventDefault();
    if (e.key === 'Enter') {
      this.hundleGoBack();
    }
  };

  hundleKeyPressedSubmit = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  hundleGoBack = () => {
    const { back } = this.props.location.state;
    const { history } = this.props;
    history.push(back);
  };

  // hundleGoNext = () => {
  //   const { next } = this.props.location.state;
  //   const { history } = this.props;
  //   history.push(next);
  // };

  render() {
    // eslint-disable-next-line no-unused-vars
    const {
      email,
      password,
      isError,
      errorMessage,
      isReturn,
      isToLogin,
      isToNext,
    } = this.state;
    const emailId = shortid.generate();
    const passwordId = shortid.generate();
    const { location } = this.props;
    const { state } = location;
    const { back, next, isForward } = state || INIT_STATE;
    // const { next, isForward } = state || INIT_STATE;
    return (
      <div className={s.container}>
        <div className={s.formWrapper}>
          <h1 className={s.title}>
            Для прохождения теста необходимо зарегистрироваться
          </h1>
          <form className={s.form} onSubmit={this.handleSubmit}>
            <Input
              id={emailId}
              labelText=" E-mail"
              type="email"
              name="email"
              initValue={email}
              change={this.handleChange}
              keyAction={this.hundleKeyPressedSubmit}
            />
            <Input
              id={passwordId}
              labelText=" Пароль"
              type="password"
              name="password"
              initValue={password}
              change={this.handleChange}
              keyAction={this.hundleKeyPressedSubmit}
              title="Используйте  не менее 6 символов с обязательными цифрами, буквами верхнего и нижнего регистра и подчеркиванием"
            />
            <div className={s.buttonWrapper}>
              {isReturn ? (
                <>
                  <button
                    className={s.btn}
                    type="button"
                    onClick={this.hundleGoBack}
                  >
                    назад
                  </button>
                  <button className={s.btnAcent} type="submit">
                    регистрация
                  </button>
                </>
              ) : null}
            </div>
            {isForward ? (
              <a href={next} className={s.consent} type="">
                Продолжаю дальше без регистрации
              </a>
            ) : null}
          </form>
          {isToLogin && (
            <Redirect
              to={{
                pathname: routes.LOGIN,
                state: {
                  message:
                    'Вы перешли на страницу входа, т.к. этот email уже зарегистрирован.',
                  back,
                  next,
                },
              }}
            />
          )}
          {isToNext && (
            <Redirect
              to={{
                pathname: next,
              }}
            />
          )}
          {isError && (
            <ErrorMessage
              message={errorMessage}
              changeMessage={this.handleBack}
            />
          )}
        </div>
      </div>
    );
  }
}

export default WithUser(RegistrationPage);
