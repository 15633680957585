import React from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import T from 'prop-types';

import 'codemirror/lib/codemirror.css';
import '../../styles/material.css';
import 'codemirror/theme/neat.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';

const options = {
  mode: 'javascript',
  theme: 'material',
  tabSize: 4,
  lineNumbers: true,
  readOnly: true,
};

const CodeViewer = props => {
  const { value } = props;
  return <CodeMirror value={value} options={options} />;
};

CodeViewer.propTypes = {
  value: T.string.isRequired,
};

export default CodeViewer;
