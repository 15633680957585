const loadSession = (name = 'ident') => {
  try {
    const serializedState = sessionStorage.getItem(name);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveSession = (state, name = 'ident') => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(name, serializedState);
  } catch (err) {
    // ignore write errors
  }
};

export { loadSession, saveSession };
