import themes from './themes';

const defaultUser = {
  // id: '',
  // хранить в sessionStore
  email: '',
  password: '',
  // end хранить в sessionStore

  // заполнять при оформлении сертификата
  name: '',
  lastname: '',
  // end заполнять при оформлении сертификата

  // обновлять из бд //
  resolvedTasksId: {},
  // end обновлять из бд //

  // resolvedTasksId_js: {},
  // не обновлять из БД
  currentTaskId: '',
  currentTheme: 'js',
  isComplited: {},
  isReceivedCertificate: false,
  // end не обновлять из БД,
  // генерируется сервером
  token: '',
};

// инициация в зависимости от количества тем
const setProps = arr =>
  arr.forEach(e => {
    defaultUser.resolvedTasksId[e] = [];
    defaultUser.isComplited[e] = false;
  });

setProps(themes, defaultUser);

export default defaultUser;
