/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import routes from '../routes';
import UserContext from '../services/context/user/UserContext';
// import TaskListContext from '../services/context/taskList/TaskListContext';
import { loadLocal, saveLocal } from '../services/api/webstorage/local';
import defaultUser from '../config/defaultUser';
import defaulTaskList from '../config/defaultTaskList';

import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import RegistrationPage from '../pages/RegistrationPage';
import TaskPage from '../pages/TaskPage';
import CertificatePage from '../pages/CertificatePage';
import Layout from './Layout';
import ErrorBoundary from './ErrorBoundary';
// import { fetchGetTasksTopics } from '../services/api/db-task/api-db-task';
import { getTasksTopics } from '../services/api/db-task/api-data-task';
import ErrorMessage from './ErrorMessage';

const user = loadLocal('user') || defaultUser;
let taskList = defaulTaskList;

const useRequest = request => {
  const initialState = useMemo(
    () => ({
      data: taskList,
      isLoading: true,
      error: null,
    }),
    [],
  );

  const [dataSet, setDataSet] = useState(initialState);

  useEffect(() => {
    setDataSet(initialState);
    let canceled = false;
    request()
      .then(
        data =>
          !canceled &&
          setDataSet({
            data,
            isLoading: false,
            error: null,
          }),
      )
      .catch(
        err =>
          !canceled &&
          setDataSet({
            data: null,
            isLoading: false,
            error: err,
          }),
      );
    return () => {
      canceled = true;
    };
  }, [request, initialState]);
  return dataSet;
};

const useTaskList = () => {
  // const request = useCallback(() => fetchGetTasksTopics(), []);
  const request = useCallback(() => getTasksTopics(), []);
  return useRequest(request);
};

function App() {
  const { data, isLoading, error } = useTaskList();
  saveLocal(data, 'taskList');
  taskList = data;

  if (error) {
    return ErrorMessage(error);
  }
  return (
    <>
      <BrowserRouter>
        <ErrorBoundary>
          <UserContext.Provider value={user}>
            {/* <TaskListContext.Provider value={taskList}> */}
            <Layout>
              <Switch>
                {/* <Route exact path={routes.HOME} component={HomePage} /> */}
                <Route
                  exact
                  path={routes.HOME}
                  render={props => (
                    <HomePage
                      {...props}
                      taskList={taskList}
                      isLoading={isLoading}
                    />
                  )}
                />
                <Route path={routes.CERTIFICATE} component={CertificatePage} />
                <Route path={routes.LOGIN} component={LoginPage} />
                <Route
                  path={routes.REGISTRATION}
                  component={RegistrationPage}
                />
                <Route exact path={routes.TASK} component={TaskPage} />
                <Redirect to={routes.HOME} />
              </Switch>
            </Layout>
            {/* </TaskListContext.Provider> */}
          </UserContext.Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </>
  );
}

export default App;
